<template>
  <div class="auth-footer">
    <p>© 2025 Synergy. Все права защищены</p>
    <nuxt-link to="/privacy">
      Политика конфиденциальности
    </nuxt-link>
    <nuxt-link to="/rekviziti">
      Реквизиты
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'AuthFooter',
};
</script>

<style scoped lang="scss">

.auth-footer {
  display: flex;
  align-items: center;

  @include media-down($size-mobile) {
    flex-wrap: wrap;
    gap: calc(var(--scale) * 12);
  }

  p {
    font-weight: 500;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;
    color: #A7ABAC;

    @include media-down($size-mobile) {
      order: 1;
      width: 100%;
      text-align: center;
    }
  }

  a {
    font-weight: 500;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;
    text-decoration-line: underline;
    color: #A7ABAC;

    &:first-of-type {
      margin-left: auto;

      @include media-down($size-mobile) {
        margin-left: 0;
      }
    }

    &:last-of-type {
      margin-left: calc(var(--scale) * 231);

      @include media-down($size-tablet) {
        margin-left: calc(var(--scale) * 20);
      }

      @include media-down($size-mobile) {
        margin-left: auto;
      }
    }
  }
}

</style>
