<template>
  <main class="login-page">
    <auth-header />

    <div class="login-page__content">
      <img
        src="/auth/login.webp"
        alt="login"
      >
      <login-form class="login-page__form" />
    </div>

    <auth-footer />
  </main>
</template>

<script>
import HeadMixin from '~/mixins/HeadMixin';
import AuthHeader from '~/components/auth/AuthHeader.vue';
import AuthFooter from '~/components/auth/AuthFooter.vue';
import LoginForm from '~/components/auth/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    AuthFooter,
    AuthHeader,
  },
  mixins: [HeadMixin],
  layout: 'emptyLayout',
  data: () => ({
    // для head
    title: 'Вход в личный кабинет  | Школьный Университет «Синергия»',
    // eslint-disable-next-line max-len,vue/max-len
    description: 'Вход в личный кабинет  | Школьный Университет «Синергия»',
    image: '/auth/login-share-image.jpg',
  }),
};
</script>

<style scoped lang="scss">

.login-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: calc(var(--scale) * 10) calc(var(--scale) * 20) calc(var(--scale) * 16) calc(var(--scale) * 40);
  background-color: #ECEDF0;

  @include media-down($size-tablet) {
    padding: calc(var(--scale) * 16);
  }

  @include media-down($size-mobile) {
    padding: calc(var(--scale) * 12);
  }

  ::v-deep(*) {
    font-family: "Onest", sans-serif;
  }

  &__content {
    display: flex;
    justify-content: center;
    gap: calc(var(--scale) * 20);
    margin-top: calc(var(--scale) * 80);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 8);
      margin-top: calc(var(--scale) * 12);
    }

    @include media-down($size-mobile) {
      margin-block: calc(var(--scale) * 8);
      flex-direction: column;
    }

    & > img {
      height: calc(var(--scale) * 560);
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: calc(var(--scale) * 40);

      @include media-down($size-tablet) {
        height: calc(var(--scale) * 366);
        border-radius: calc(var(--scale) * 32);
      }
    }
  }

  &__form {
    width: calc(var(--scale) * 366);
    height: calc(var(--scale) * 560);
  }

  .auth-footer {
    margin-top: auto;
  }
}

</style>
