<template>
  <div
    class="login-form"
    @keypress.enter="submit"
  >
    <h1 class="login-form__title">
      Вход на платформу
    </h1>

    <p
      v-if="submitError"
      class="login-form__error"
    >
      Почта или пароль введены неверно
    </p>

    <form-email-field
      ref="emailField"
      v-model="email"
      :submit-error="submitError ? [{ invalidLogin: true }] : []"
      class="login-form__email"
      @clearSubmitError="clearSubmitError"
    />

    <form-password-field
      ref="passwordField"
      v-model="password"
      :submit-error="submitError ? [{ invalidPassword: true }] : []"
      class="login-form__password"
      have-eye
      @clearSubmitError="clearSubmitError"
    />

    <button
      class="login-form__submit m-btn"
      type="submit"
      :disabled="sending"
      @click="submit"
    >
      Войти
    </button>

    <nuxt-link
      class="login-form__register m-btn"
      to="/signup"
    >
      У меня еще нет аккаунта
    </nuxt-link>

    <nuxt-link
      class="login-form__remember m-btn"
      to="/remember"
    >
      Я забыл(а) пароль и хочу его восстановить
    </nuxt-link>
  </div>
</template>

<script>
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPasswordField from '@/components/v2/common/fileds/FormPasswordField.vue';
import redirector from '@/service/redirector';

export default {
  name: 'LoginForm',
  components: { FormPasswordField, FormEmailField },

  data() {
    return {
      email: this.$store.getters.loginEmail,
      password: '',
      submitError: false,
      sending: false,
    };
  },

  methods: {
    clearSubmitError() {
      this.submitError = false;
    },

    validateForm() {
      let valid = true;

      if (!this.$refs.emailField.validate()) {
        valid = false;
      }

      if (!this.$refs.passwordField.validate()) {
        valid = false;
      }

      return valid;
    },

    async submit() {
      this.submitError = false;

      if (!this.validateForm()) {
        return;
      }

      this.sending = true;

      const response = await this.$api.login(this.email, this.password);

      if (response.role) {
        this.$store.dispatch('login', response);
        redirector.toCore();
      } else {
        this.submitError = true;
      }

      this.sending = false;
    },
  },
};

</script>

<style scoped lang="scss">

.login-form {
  padding: calc(var(--scale) * 20) calc(var(--scale) * 16);
  display: flex;
  flex-direction: column;
  border-radius: calc(var(--scale) * 32);
  background-color: #ffffff;

  &__title {
    margin-bottom: calc(var(--scale) * 20);
    font-weight: 500;
    font-size: calc(var(--scale) * 28);
    line-height: 95%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #02120F;
  }

  &__error {
    margin-bottom: calc(var(--scale) * 20);
    margin-top: calc(-1 * var(--scale) * 12);

    font-weight: 500;
    font-size: calc(var(--scale) * 16);
    line-height: 130%;
    text-align: center;
    color: #FF0040;
  }

  &__email {
    width: 100%;
    margin-bottom: calc(var(--scale) * 8);
  }

  &__password {
    margin-bottom: calc(var(--scale) * 16);
  }

  ::v-deep(input) {
    width: 100%;
    height: auto;
    padding: calc(var(--scale) * 22) calc(var(--scale) * 24);
    border: 1px solid #CACACB;
    border-radius: calc(var(--scale) * 24);
    background: #ffffff !important;

    font-weight: 500;
    font-size: calc(var(--scale) * 18);
    line-height: 120%;
    color: #02120F;

    &::placeholder {
      color: #A7ABAC;
    }
  }

  ::v-deep(.error-message) {
    display: none;
  }

  &__submit {
    margin-bottom: calc(var(--scale) * 8);

    padding: calc(var(--scale) * 30) calc(var(--scale) * 10);
    background-color: #FF5319;
    border-radius: calc(var(--scale) * 24);

    font-weight: 500;
    font-size: calc(var(--scale) * 18);
    line-height: 110%;
    color: #FFFFFF;

    @media (hover: hover) {
      &:hover {
        background-color: #FF9C12;
      }
    }
  }

  &__register {
    padding: calc(var(--scale) * 29) calc(var(--scale) * 10);
    background-color: transparent;
    border-radius: calc(var(--scale) * 24);
    border: 1px solid #5237E6;

    font-weight: 500;
    font-size: calc(var(--scale) * 18);
    line-height: 110%;
    color: #5237E6;
    text-align: center;

    @media (hover: hover) {
      &:hover {
        background-color: rgba(#5237E6, 0.1);
      }
    }
  }

  &__remember {
    margin-top: auto;
    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    text-align: center;
    text-decoration-line: underline;
    color: #FF5319;

    @media (hover: hover) {
      &:hover {
        color: #FF9C12;
      }
    }
  }
}

</style>
