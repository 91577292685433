<template>
  <div class="auth-header">
    <button
      class="m-btn auth-header__back"
      @click="goToBack"
    >
      <img
        src="/auth/arrow-back.svg"
        alt="back"
      >
      <span>Вернуться назад</span>
    </button>

    <nuxt-link
      to="/"
      class="auth-header__close"
    >
      <img
        src="/auth/close.svg"
        alt="close"
      >
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'AuthHeader',
  methods: {
    goToBack() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped lang="scss">

.auth-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__back {
    display: flex;
    align-items: center;
    gap: calc(var(--scale) * 12);

    img {
      width: calc(var(--scale) * 10);
      height: calc(var(--scale) * 10);
      margin-top: calc(-1 * var(--scale) * 2);
    }

    span {
      font-weight: 500;
      font-size: calc(var(--scale) * 14);
      line-height: 130%;
      color: #02120F;
    }
  }

  &__close {
    width: calc(var(--scale) * 33);
    height: calc(var(--scale) * 33);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: calc(var(--scale) * 15);
    }
  }
}

</style>
